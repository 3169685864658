import React from "react"
export default function Chat() {
  return (
    <div style={{ width: "100%", height: "100%", margin: "0px" }}>
      <link
        href="https://static.convaise.com/popup/latest/popup-v1-min.css"
        rel="stylesheet"
      />
      <div id="convaise-wrapper">
        <div class="convaisecss-clear">
          <div id="convaise-chat" class="convaisecss-chat-content">
            <div class="convaisecss-chat-body">
              <iframe
                src="https://bots.convaise.com/computacenter/stt"
                allow="microphone;"
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </div>
          <div id="convaise-bttns">
            <button
              type="button"
              aria-label="Chat öffnen"
              id="openConvaiseChat"
              class="convaiscss-bttn"
            >
              <div
                class="convaisecss-start-bttn--text"
                style={{ paddingLeft: "18px" }}
              >
                Förderantrag
              </div>
            </button>
            <button
              type="button"
              aria-label="Chat entfernen"
              id="removeConvaiseChat"
              class="convaiscss-bttn convaisecss-remove-bttn"
            >
              <svg
                fill="currentColor"
                preserveAspectRatio="xMidYMid meet"
                height="22"
                width="22"
                viewBox="0 0 40 40"
                style={{ verticalAlign: "middle" }}
              >
                <g>
                  <path d="m33.5 29.5q0 0.9-0.7 1.5l-3 3.1q-0.6 0.6-1.5 0.6t-1.5-0.6l-6.6-6.6-6.5 6.6q-0.7 0.6-1.6 0.6t-1.5-0.6l-3-3.1q-0.6-0.6-0.6-1.5t0.6-1.5l6.5-6.6-6.5-6.5q-0.6-0.7-0.6-1.6t0.6-1.5l3-3q0.6-0.6 1.5-0.6t1.6 0.6l6.5 6.6 6.6-6.6q0.6-0.6 1.5-0.6t1.5 0.6l3.1 3q0.6 0.7 0.6 1.5t-0.6 1.6l-6.6 6.5 6.6 6.6q0.6 0.6 0.6 1.5z"></path>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <script
        type="text/javascript"
        src="https://static.convaise.com/popup/latest/popup-v1-min.js"
      ></script>
    </div>
  )
}
